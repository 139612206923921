// Chat Added by Yash Govekar

@import "variables";
@import "macros";

.unread-msg {
  border-radius: 50%;
  background-color: red;
  height: 0.5em;
  width: 0.5em;
  position: relative;
  right: 0.25em;
  top: -0.4em;
}

.chat-container {
  height: 100%;

  .messages-container {
    flex-direction: column;
    overflow-y: scroll;
    margin-bottom: 1em;
    padding-right: 0.2em;
    padding-left: 0.2em;
  }

  input {
    border: 1px solid gray;
    background: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 100%;
    color: #1e89f6;
    margin-right: 0.5rem;
  }

  p {
    color: #212529;
    padding: 10px 15px !important;
    margin-bottom: 5px !important;
    display: inline-block !important;
    border-radius: 0.25rem !important;
    line-height: 1.6;
  }

  .bg-light {
    background-color: #c8e2f8 !important;
  }

  .bg-dark {
    background-color: #e4e7ed !important;
  }

  .save-btn {
    display: inline-block;
    margin-top: 0.5em;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
